body {
    font-family: Poppins, sans-serif;
}

.App {
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.btn-primary {
    color: #ffffff;
    background-color: #F01C30;
}

#opportunities-hero {
    background-image: url('/src/images/circular.png');
    background-size: 200px;
    background-position: top right;
    background-repeat: no-repeat;
    min-height: 400px;
    /* Adjust as needed */
    display: flex;
    align-items: center;
}

#opportunities-hero h6 {
    font-size: 1rem;
    letter-spacing: 2px;
}

#opportunities-hero h1 {
    font-size: 2.5rem;
    line-height: 1.2;
}

@media (min-width: 992px) {
    #opportunities-hero h1 {
        font-size: 3rem;
    }
}

/* Custom styles for OpportunityDetailsScreen */
.custom-img {
    border: none;
}

.custom-btn:hover {
    background-color: #dc3545;
    border-color: #dc3545;
}

.custom-badge {
    background-color: #d0e7ff;
    color: #000;
}

.truncate-multi {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; /* Number of lines to show */
}

.clickable {
    transition: box-shadow 0.3s ease;
}

.clickable:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
