.navbar-logo {
  width: auto;
  height: 40px;
}

.btn-get-started {
  background-color: #ec345c;
  border-color: #ec345c;
  padding-left: 30px;
  padding-right: 30px;
  font-size: smaller;
}
#section-three {
  position: relative;
  background-image: url("../src/images/circular.png");
  background-position: calc(100% + 50px)-100px;
  background-repeat: no-repeat;
  background-size: 300px 300px;
}
#features .features-text{
  text-align: center;
}

.w-410 {
  width: 410px;
}

.h-361 {
  height: 361px;
}
.h-100px {
  height: 100px;
}
.pt-7{
  padding-top: 7rem;
}
.mt-6{
  margin-top: 6rem;
}
.px-6{
  padding-left: 3rem;
  padding-right: 3rem;
}
.collage {
  display: inline-block;
  justify-content: center;
  text-align: right;
}
.collage img {
  display: inline-grid;
  max-width: 250px;
  margin: 0 0 0 30px;
}

.collage-card {
  width: 95%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-left: 20px;*/
  margin-bottom: 30px;
  border-radius: 10px;
  /*background-attachment: fixed;*/
  background-size: cover;
  background-position: center;
  height: 450px;
}

.overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  border-radius: 10px;
}

.bg-pink {
  background-color: #ec345c;
  color: white;
}

.bg-black {
  color: white;
}

.bg-red {
  background-color: #f01c30;
  color: white;
}

.border-white{
  border-color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.section-title {
  font-weight: bold;
  padding: 15px 0;
}
.text-hover-danger:hover {
  color: #f01c30;
}


.floating-image {
  position: absolute;
  max-width: 380px;
  height: auto;
  margin-top: -280px;
}

.fs-6 {
  font-size: small !important;
}
.fs-7 {
  font-size: .95rem !important;
}

.story-numbers {
  padding-top: 15px;
}

.story-numbers label {
  text-transform: uppercase;
  font-size: 0.8rem;
  border: solid 1px #ffffff;
  padding: 2px 15px;
  border-radius: 12px;
}

.story-numbers h1 {
  font-size: 3rem;
  font-weight: 700;
  /*color: #f01c30;*/
  background: linear-gradient(#f01c30, #bf40bf);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.accordion-summary {
  font-weight: 600;
  padding: 10px 0;
}

.accordion-summary > .summary-text {
  width: 80%;
}
.h-35 {
  height: 35px;
}

.fs-12 {
  font-size: 12px;
}
.max-width-700px {
    max-width:700px;
}
.max-width-900px {
    max-width:900px;
}
.max-width-350 {
    max-width:350px;
}
.min-height-200px {
    min-height:200px;
}
.min-input-field-height{
  min-height: calc(1.5em + 1rem + calc(1px* 2));
}
.h-200px {
  height: 200px;
}
a {
  text-decoration: none;
  color: #ec345c;
}

.text-smaller {
  font-size: 0.8em;
}

.text-primary {
  color: #ec345c !important;
}
.custom-focus-outline:focus {
  outline: none;
  box-shadow: none;
  border-color: #a7aab4;
}
.custom-icon {
  -webkit-mask: url("images/svgs/arrow-right-circle-fill.svg") no-repeat center;
  mask: url("images/svgs/arrow-right-circle-fill.svg") no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  width: 48px;
  height: 48px;
}

.custom-icon::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 1px solid #ffffff; /* White border */
}

/* Opportunities sectors filters */
/* Default: show all */
.category-item {
  display: block;
}

.opportunities-hero .description span {
  line-height: 33px;
}

.me-0.custom-modal .modal-content {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0;
  margin-right: 0;
  width: 100%;
}

/* Begin multi select */

.multi-container {
  position: relative;
  width: 24em;
  min-height: calc(0.5em + 1rem + calc(1px * 2));
  border: 1px solid #e4e6ef;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.2em;
  border-radius: 0.475rem;
  outline: none;
}

.multi-container-lg {
  min-width: 30em;
  min-height: calc(1.5em + 2.75rem + calc(1px * 2));
}

.multi-container:focus {
  border-color: #c4c6cf;
}

.multi-value {
  flex-grow: 1;
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

.multi-clear-btn {
  background: none;
  color: #616162;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.25em;
}

.multi-clear-btn:focus,
.multi-clear-btn:hover {
  color: #616162;
}

.multi-divider {
  background-color: #616162;
  align-self: stretch;
  width: 0.05em;
}

.multi-caret {
  translate: 0 25%;
  border: 0.25em solid transparent;
  border-top-color: #616162;
  cursor: pointer;
}

.multi-options {
  position: absolute;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  max-height: 15em;
  overflow-y: auto;
  border: 0.05em solid #e4e6ef;
  border-radius: 0.475rem;
  width: 100%;
  left: 0;
  top: calc(100% + 0.25em);
  background-color: white;
  z-index: 100;
}

.multi-options.show {
  display: block;
}

.multi-option {
  padding: 0.25em 0.5em;
  cursor: pointer;
}

.multi-option.selected {
  background-color: #f8839c;
}

.multi-option.highlighted {
  background-color: #f1416c;
  color: white;
}

.multi-option-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.05em solid #e4e6ef;
  border-radius: 1rem;
  padding: 0.15em 0.25em;
  gap: 0.25em;
  cursor: pointer;
  background: none;
  outline: none;
  color: #616162;
  font-weight: 300;
  background-color: #D9D9D9;
}

.multi-option-badge:hover,
.multi-option-badge:focus {
  background-color: hsl(0, 100%, 90%);
  border-color: hsl(0, 100%, 50%);
}


.multi-option-badge:hover > .remove-btn,
.multi-option-badge:focus > .remove-btn {
  color: hsl(0, 100%, 50%);
}

.multi-option-badge > .remove-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  color: #FFFFFF;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #ACADAF;
  margin-left: 0.5em;
  padding-top: 0.125em;
}
/* End multi-select */

/* Media Queries */
@media (max-width: 480px) {
  .category-item:nth-child(n + 3):not(.more-filters) {
    display: none;
  }
  .opportunities-hero .description {
    padding-top:10px;
    padding-bottom:10px;
  }
  .opportunities-hero .description span {
    font-size: 20px !important;
    line-height: 20px;
    padding-top:50px;
  }
  .opportunities-search input {
    padding-left: 1rem !important;
  }
  .opportunities-search button {
    padding-left: 1.5rem !important;
  }
}

/* 480px to 768px: show first 4 and "More filters" */
@media (min-width: 481px) and (max-width: 768px) {
  .category-item:nth-child(n + 4):not(.more-filters) {
    display: none;
  }
}

/* Above 768px: show first 5 and "More filters" */
@media (min-width: 769px) {
  .category-item:nth-child(n + 6):not(.more-filters) {
    display: none;
  }
}
@media (max-width: 769px) {
  .home .call-to-action{
    padding-top: 0;
  }
  #features .features-text {
    text-align: start !important;
  }
  #features .features-text h4{
    max-width: 900px !important;
  }
}
/* Media Queries */


